'use strict';

angular.module('enervexSalesappApp').controller('ApplianceUtilityCtrl', function($scope, Application, _, ApplianceCompany, ApplianceModel, FileUploader, Flash, SystemTask){
	$scope.upload = {}
	Application.query().$promise.then(function(result){
		$scope.applications = _.filter(result, function(app){
			return app.properties.appliances
		})
		console.log("applications", $scope.applications)
	})
	ApplianceCompany.query({
		limit: 3000
	}).$promise.then(function(applianceCompanies){
		$scope.allApplianceCompanies = applianceCompanies;
		$scope.updateCompanies()
	})
	ApplianceModel.query({
		limit:10000
	}).$promise.then(function(applianceModels){
		$scope.allApplianceModels = applianceModels;
	})
	$scope.allFields = "model,applianceCompany.*,applianceCompany._id,icon,volume,computeAs,startingConfig,startingPointProduct,productSubtype,active,baro,cat,isDuplicate,isPlumeAbatement,outletdamper,outletdia,presmax,presmin,fuels.*,applications.*".split(",")
	$scope.fields = _.map($scope.allFields, function(f){
		return {
			selected: false,
			name: f
		}
	})
	$scope.useAllFields = true
	Flash.clear()

	$scope.updateModels = function() {
		var applicationId = $scope.upload.application && $scope.upload.application._id,
			companyId = $scope.upload.applianceCompany && $scope.upload.applianceCompany._id;

		$scope.applianceModels = _.filter($scope.allApplianceModels, function(applianceModel){
			if (applicationId) {
				var existing = _.find(applianceModel.applications, function(a){
					return a == applicationId
				})
				if (!existing){
					return false
				}
			}
			if (companyId) {
				return applianceModel.applianceCompany && applianceModel.applianceCompany._id == companyId;
			}
			return true
		})
	}
	$scope.export = function() {
		var params = [];
		if ($scope.upload.application && $scope.upload.application._id){
			params.push("application="+$scope.upload.application._id)
		}
		if ($scope.upload.applianceCompany && $scope.upload.applianceCompany._id){
			params.push("applianceCompany="+$scope.upload.applianceCompany._id)
		}
		if ($scope.upload.applianceModel && $scope.upload.applianceModel.model){
			params.push("model="+$scope.upload.applianceModel.model)
		}
		if ($scope.upload.isDuplicate && $scope.upload.isDuplicate != ""){
			params.push("isDuplicate="+$scope.upload.isDuplicate)
		}
		var url = "/api/appliance-models/export.csv";
		if (params.length >0) {
			url = url + "?" + params.join("&")
		}
		window.location.href = url;
	}
	$scope.updateCompanies = function() {
		if ($scope.upload.application && $scope.upload.application._id) {
			$scope.applianceCompanies = _.filter($scope.allApplianceCompanies, function(applianceCompany){
				var existing = _.find(applianceCompany.applications, function(application){
					return application._id == $scope.upload.application._id
				})
				return (existing) ? true: false
			})
		} else {
			$scope.applianceCompanies = $scope.allApplianceCompanies
		}
		$scope.updateModels()
	}
	var uploader = $scope.uploader = new FileUploader({});
	function pollTask(systemTask) {
		$scope.systemTask = systemTask
		var diff = new Date().getTime() - new Date(systemTask.startedAt).getTime()
		var level = "warning"
		var additionalNotes = ""
		if ($scope.systemTask.status == "running") {
			additionalNotes = " started " + diff/1000 + " seconds ago"
		} else if ($scope.systemTask.status == "success") {
			level = "info"
		} else if ($scope.systemTask.status == "errored") {
			level = "danger"
		}

		Flash.clear()
		var anchor = '<a href="/system-tasks/' + $scope.systemTask._id + '">' + $scope.systemTask.taskNumber + '</a>'
		Flash.create(level, '<strong>Import system task ' + anchor + 
			" is " + $scope.systemTask.status + '</strong> ' + additionalNotes + $scope.systemTask.notes, 0, {}, true);
		setTimeout(function() {
			if ($scope.systemTask && $scope.systemTask.status == "running") {
				SystemTask.get({
					id: $scope.systemTask.id
				}).$promise.then(function(res) {
					pollTask(res)
				})
			}
		}, 5000)
	}
	$scope.import = function() {
		_.each($scope.uploader.queue, function(data){
			var fd = new FormData();
			if (!$scope.useAllFields) {
				var filtered = _.filter($scope.fields, function(field){
					return field.selected
				})
				var vals = _.map(filtered, function(o){
					return o.name
				}).join(",")
				fd.append('fields', vals);
			}
			fd.append('name', data._file.name);
			fd.append('asset', data._file);
			// $scope.uploadResponse = false;
			ApplianceModel.import(fd).$promise.then(function(res){
				pollTask(res)
				// console.log("success", res)
				// $scope.uploadResponse = res
			})
		});
	}

});
